import { mapGetters } from 'vuex'
export default {
  name: 'about',
  data: () => ({
    contentInService: [
      {
        headerImage: 'section_icon_01',
        headerText: 'Advanced digital education',
        bodyText: 'Equipping you with the knowledge and opportunities needed to excel in a digitised era.',
        linkText: 'Explore our programs.',
        action: 'Program'
      },
      {
        headerImage: 'section_icon_02',
        headerText: 'Integrated talent pipeline',
        bodyText: 'End-to-end services focused on supplying your business with industry-leading digital talent.',
        linkText: 'Learn more about our partner.',
        action: 'Corporate'
      },
      {
        headerImage: 'section_icon_03',
        headerText: 'Innovative technology solutions',
        bodyText: 'Driving technology projects and initiatives aimed at bringing impactful long-term results.',
        linkText: 'Learn more about G2Lab.',
        action: 'G2Lab'
      },
      {
        headerImage: 'section_icon_04',
        headerText: 'AI powered education platforms',
        bodyText: 'Delivering greater consistency, flexibility and transparency to your personalised learning journey.',
        linkText: 'Learn more about our platform.',
        action: 'Products'
      }
    ],
    team: [
      {
        title: 'Management',
        cssClass: 'management',
        people: [
          [
            {
              name: 'Sebastian Togelang',
              title: 'Founder & <br/> Komisaris Utama',
              photo: require('@/assets/images/people/SebastianTogelang.png')
            },
            {
              name: 'Gita Wirjawan',
              title: 'Komisaris',
              photo: require('@/assets/images/people/gita.png')
            }
          ],
          [
            {
              name: 'Aries Tjandra',
              title: 'Direktur Utama',
              photo: require('@/assets/images/people/Aries.png')
            },
            {
              name: 'Ariawan Wijaya',
              title: 'Direktur',
              photo: require('@/assets/images/people/ariawan.png')
            },
            {
              name: 'Syed Ali',
              title: 'CEO',
              photo: require('@/assets/images/people/syed.png')
            }
          ]
        ]
      },

      {
        title: 'Prakerja',
        cssClass: 'prakerja',
        people: [
          [
            {
              name: 'Muhammad Devakto',
              title: 'Product Manager',
              photo: require('@/assets/images/people/devakto.png')
            },
            {
              name: 'Hendri Kurniawan',
              title: 'Academic Operations Specialist',
              photo: require('@/assets/images/people/hendri.webp')
            }
          ]
        ]
      }
    ]
  }),
  components: {
    ImageItem: () => import(/* webpackChunkName: "image" */ '@/components/image/ImageItem'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button')
  },
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.$route.meta.metaTags
    }
  },
  computed: {
    ...mapGetters('common', ['isMobile']),
    activeLanguange() {
      return this.$i18n.locale
    },
    textInOurStory() {
      return this.$i18n.messages[this.activeLanguange].aboutUs.story.textInOurStory
    },
    textInFounder() {
      return this.$i18n.messages[this.activeLanguange].aboutUs.story.textInFounder
    },
    whatWeProvideItems() {
      return this.$i18n.messages[this.activeLanguange].aboutUs.whatWeProvide.items
    }
  },
  methods: {
    getMemberList(member) {
      if (!this.isMobile) {
        return member
      } else {
        const temp = []
        const mergedArray = [].concat(...member)
        temp.push(mergedArray)
        return temp
      }
    },
    toSelectedPage(page) {
      switch (page) {
        case 'Program':
          this.$router.push({ name: 'Programs' })
          break
        case 'Corporate':
          this.$router.push({ name: 'G2Lab' })
          break
        case 'Products':
          this.$router.push({ name: 'Products' })
          break
        case 'G2Lab':
          this.$router.push({ name: 'G2Lab' })
          break
      }
    },
    toContactUs() {
      this.$router.push({ name: 'Contact Us' })
    }
  }
}
